@font-face {
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/FuturaPTBold.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/OpenSans-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/OpenSans-Italic.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/OpenSans-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/OpenSans-BoldItalic.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/OpenSans-ExtraBold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/OpenSans-ExtraBoldItalic.ttf');
  font-display: swap;
}

html {
  * {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    max-width: 100%;
  }
}

.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  font-family: Arial, sans-serif;
  box-shadow: none;
  border: 1px solid #d9d9d9;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 5px;
}

.pac-container:after {
  content: none !important;
}

.pac-item {
  padding: 10px;
  font-size: 12px;
  cursor: pointer;

  font-weight: 500;
}

.pac-item:hover {
  background-color: #ec008c;
  span {
    color: white;
  }
  .pac-icon {
    background-image: url(../assets/location-white.png);
  }
}
.pac-item-query {
  font-size: 12px;
}

.pac-icon {
  background-image: url(../assets/location-pink.png);
  width: 18px;
  height: 18px;
  background-size: 18px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
}

.hdpi .pac-icon {
  background-image: url(../assets/location-pink.png);
}

html {
  background-color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiPopover-root {
  z-index: 999999 !important;
}

.title {
  font-family: Futura PT !important;
  text-transform: capitalize;
}

* {
  box-sizing: border-box;
}
